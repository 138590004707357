import React from 'react';
import Cat from '../Components/Cat';
import Cat1 from '../Components/Cat1';
import HomeForm from '../Components/HomeForm';
import PortfolioItem from '../Components/Portfolio';
import PortfolioSlider from '../Components/PortfolioSlider';
import TestimonialSlider from '../Components/TestimonialSlider';
import { useMainContext } from '../Context/MainContext';
const Portfolio = () => {
  const { allPortfolio,
    setPortFolio,
    getAllPortfolio } = useMainContext();
  return (
    <>
      <PortfolioSlider  />
      <PortfolioItem data={allPortfolio} onClickMenu={(d)=>{
        setPortFolio(null)
        getAllPortfolio(d);
      }} bg={'linear-gradient(45deg, #25328ef2, transparent)'} />
      {/* <Cat1 /> */}
      {/* <Cat /> */}
      {/* <TestimonialSlider  /> */}
      {/* <HomeTestimonial/> */}
      <HomeForm />
    </>
  )
}

export default Portfolio