import React, { useEffect } from 'react'
import Cat from '../Components/Cat'
import Cat1 from '../Components/Cat1'
import HelpSec from '../Components/HelpSec'
import HomeForm from '../Components/HomeForm'
import TestimonialSlider from '../Components/TestimonialSlider'
import { gsap } from "gsap";
import Typed from 'typed.js';

const About = () => {


  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['A Fully Integrated <br /> Digital Design Agency'],
      typeSpeed: 50,
      loop: true,
      startDelay: 500
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);


  const onEnter = (e) => {
    gsap.to(e.target, { scale: 1.2 });
  };

  const onLeave = (e) => {
    gsap.to(e.target, { scale: 1 });
  };

  return (
    <>
      <section
        className="inner-banner"
        style={{
          background: "url(images/about-bg.png)",
          backgroundSize: "cover",
          backgroundPosition: "bottom right",
           height:"100vh",
           paddingTop:"5rem"
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 banner-left banner-left123">
              <h1>
                <span ref={el} />

                {/* A Fully Integrated <br /> <span>Digital Design Agency </span>{" "} */}
              </h1>
              {/* <h1 className="ml11">
                <span className="text-wrapper">
                  <span className="line line1"></span>
                  <span className="letters"> A Fully Integrated <br /> Digital Design Agency </span>
                </span>
              </h1> */}

              <p>
                At Mad Alpha Designer, we're firm believers that within every
                challenge lies an opportunity waiting to be seized. With a team of
                imaginative minds and adept troubleshooters, we're committed to
                pushing boundaries and unlocking new possibilities. Our mission is to
                empower brands in accomplishing their objectives by redefining what's
                achievable.
              </p>
              <ul className="banner-list">
                <li>TOP-NOTCH DESIGNERS AND DEVELOPERS</li>
                <li>Fastest Turn-Around Time</li>
                <li>100% Money-Back Guarantee</li>
                <li>100% Customer Satisfaction</li>
              </ul>{" "}
              <a
              data-fancybox=""
                href="javascript:void(0)"
                data-src="#popupformWebsite"
                
                className="banner-btn yb-btn various"
                style={{ background: 'linear-gradient(197deg, #312969, #0000008a)' }}

              >
                Get Touch In{" "}
              </a>
            </div>
            <div className='col-md-4 '


            >
              <img
              className='image15'
                onMouseEnter={onEnter}
                onMouseLeave={onLeave}
                src='images/about-5.png' 
                
                />
            </div>
          </div>
        </div>
      </section>

      <HelpSec bg='#fff' />



      <section className="about-new-sec"
        style={{
          background: "linear-gradient(197deg, #312969, #0000008a)"
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 p0">
              <div className="about-img-st">
                <img src={require("../assets/images/about-1.png")} />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="about-txt-st">
                <h2>who we are</h2>
                <p>
                  At Mad Alpha Designer, we embody the intersection of creativity
                  and innovation, a collective of visionary designers dedicated to
                  transcending the conventional. Our core mission is to craft
                  digital experiences that not only stand out visually but also
                  connect on a deeper level with audiences worldwide. With every
                  project, our ambition is to challenge the status quo, merging
                  aesthetics with functionality to create something truly unique.
                  Through our commitment to excellence and a forward-thinking
                  mindset, we empower brands to forge memorable connections,
                  ensuring their stories are not just seen but felt. In the
                  ever-evolving digital landscape, Mad Alpha Designer is your
                  partner in crafting the extraordinary, where every design is a
                  step towards redefining the future.
                </p>
                <div className="about-btn">
                  {" "}
                  {/* <a href="tel:+12136726844"
                    style={{ background: 'linear-gradient(197deg, #312969, #0000008a)' }}
                    className="banner-btn yb-btn various">
                    (213) 672-6844
                  </a> */}
                  <a
                    href="javascript:$zopim.livechat.window.toggle();"
                    className="chat banner-btn yb-btn various"
                    style={{ border: '1px solid #312969' }}
                  >
                    Live Chat
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-new-sec about-new-sec2"
        style={{
          background: "#fff",
          color: "#111"
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <div className="about-txt-st">
                <h2>what we do</h2>
                <p>
                  Mad Alpha Designer stands at the forefront of digital innovation,
                  specializing in creating visually stunning and emotionally
                  engaging designs. Our expertise spans a broad spectrum of digital
                  design services, including cutting-edge 2D and 3D animations,
                  immersive graphic designs, and compelling visual storytelling. We
                  dive deep into each project, leveraging our creativity and
                  technical prowess to breathe life into our clients' visions,
                  transforming abstract ideas into tangible digital realities. By
                  staying ahead of industry trends and embracing the latest
                  technologies, we ensure that every creation is not just current
                  but also forward-thinking, setting our clients apart in their
                  respective fields.
                </p>
                <div className="about-btn">
                  {" "}
                  {/* <a href="tel:+12136726844"
                    style={{ background: 'linear-gradient(197deg, #312969, #0000008a)' }}
                    className="banner-btn yb-btn various">
                    (213) 672-6844
                  </a> */}
                  <a
                    href="javascript:$zopim.livechat.window.toggle();"
                    className="chat banner-btn yb-btn various"
                    style={{ border: '1px solid #312969', color: "#312969" }}
                  >
                    Live Chat
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 p0">
              <div className="about-img-st">
                <img src={require("../assets/images/about-2.png")} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-new-sec about-new-sec3"
        style={{
          background: "linear-gradient(197deg, #312969, #0000008a)"
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 p0">
              <div className="about-img-st">
                <img src={require("../assets/images/about-3.png")} />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="about-txt-st">
                <h2>our strength</h2>
                <p>
                  Our strength at Mad Alpha Designer lies in our ability to blend
                  creativity with strategy, a synergy that fuels our approach to
                  digital design. Our team consists of seasoned designers, creative
                  strategists, and technological innovators who bring a diverse set
                  of skills and perspectives to the table. This multidisciplinary
                  approach enables us to tackle challenges from multiple angles,
                  ensuring holistic and impactful design solutions. We pride
                  ourselves on our adaptability, our passion for learning, and our
                  commitment to pushing the boundaries of what's possible. At the
                  heart of our success is our dedication to our clients,
                  understanding their needs, and exceeding their expectations with
                  every project we undertake. In an industry where change is
                  constant, our unwavering focus on innovation and quality stands us
                  apart as leaders in digital design.
                </p>
                <div className="about-btn">
                  {" "}
                  {/* <a href="tel:+12136726844"
                    style={{ background: 'linear-gradient(197deg, #312969, #0000008a)' }}
                    className="banner-btn yb-btn various">
                    (213) 672-6844
                  </a> */}
                  <a
                    href="javascript:$zopim.livechat.window.toggle();"
                    className="chat banner-btn yb-btn various"
                    style={{ border: '1px solid #312969' }}
                  >
                    Live Chat
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our_experience"
        style={{
          background: "#fff",
          color: "#111"
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 content-header">
              <h2 className="latest-head">
                {" "}
                Over 10 Years of Experience in the <br /> Online Design Industry{" "}
              </h2>
              <p>
                The digital services we offer are extensive, yet at every point our
                work is underpinned by one core aim:
                <br />
                to drive relevant consumer traffic to your brand or website.
              </p>
            </div>
            <div className="col-md-3 exp-item">
              <div className="exp-item-inn">
                <div className="exp-item-head">
                  <h3 className="exp-name">
                    <span className="counter" style={{ color: 'rgba(135, 162, 235, 0.71)' }}>5678</span>
                  </h3>
                  <p className="exp-txt">Satisfied Customers</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 exp-item">
              <div className="exp-item-inn">
                <div className="exp-item-head">
                  <h3 className="exp-name">
                    <span className="counter" style={{ color: 'rgba(135, 162, 235, 0.71)' }}>9892</span>
                  </h3>
                  <p className="exp-txt">Projects Completed</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 exp-item">
              <div className="exp-item-inn">
                <div className="exp-item-head">
                  <h3 className="exp-name">
                    <span className="counter" style={{ color: 'rgba(135, 162, 235, 0.71)' }}>15890</span>
                  </h3>
                  <p className="exp-txt">Launched Products</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 exp-item">
              <div className="exp-item-inn">
                <div className="exp-item-head">
                  <h3 className="exp-name">
                    <span className="counter" style={{ color: 'rgba(135, 162, 235, 0.71)' }}>1015</span>
                  </h3>
                  <p className="exp-txt">Daily Visits</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Cat1 />
      <Cat />
      {/* <TestimonialSlider /> */}
      <HomeForm />

    </>
  )
}

export default About