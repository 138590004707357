import React from 'react'

const HomeForm = ({ background }) => {
    return (
        <section className="footer-form"
            style={{
                background: background ?? 'linear-gradient(45deg, #25328ef2, transparent)'
            }}
        >

            <div className="container">
                <div className="row">
                    <div className="col-md-5 contact-head">
                        <h2>
                            Ready to Take Your Streams in the Right Direction?
                            {/* <br />
                            Get Your Business <br /> the <u style={{ color: "#87a2ebb5" }} className='perfectText'>Perfect Direction </u> */}
                        </h2>
                        <h4>
                            Our design consultants will provide a comprehensive approach to help drive your business success.
                        </h4>
                    </div>
                    <div className="col-md-7 contact-form">
                        <form
                            action="webpages/bannerFormController.php"
                            method="POST"
                            className="banner-form-val"
                        >
                            <div className="cf-fieds">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="required placeholder2 form-control"
                                                required=""
                                                placeholder="Full Name"
                                                name="Name"
                                                aria-required="true"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                className="required email placeholder2 form-control"
                                                required=""
                                                placeholder="Email Address"
                                                name="Email"
                                                aria-required="true"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="required number placeholder2 form-control"
                                                minLength={10}
                                                maxLength={15}
                                                required=""
                                                placeholder="Phone Number"
                                                name="Number"
                                                aria-required="true"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="file"
                                                className="required  imageUploader1  form-control"
                                                required=""
                                                name="Number"
                                                aria-required="true"
                                                style={{ height: "40px" }}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <textarea
                                                name="Message"
                                                className="required placeholder2 form-control"
                                                required=""
                                                placeholder="To help us understand better, enter a brief description about your project."
                                                aria-required="true"
                                                defaultValue={""}
                                            />
                                        </div>
                                        <div className="sbmit-btn">
                                            <input
                                                style={{ background: 'linear-gradient(197deg, #312969, #0000008a)' }}
                                                type="submit" defaultValue="Submit " />
                                            <input
                                                className=""
                                                type="hidden"
                                                name="ctry"
                                                defaultValue=""
                                            />
                                            <input type="hidden" name="pc" defaultValue="" />
                                            <input type="hidden" name="cip" defaultValue="" />
                                            <input type="hidden" name="hiddencapcha" defaultValue="" />
                                            <input
                                                type="hidden"
                                                id="location"
                                                name="locationURL"
                                                defaultValue="http://americantechstudios.com/about-us"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default HomeForm