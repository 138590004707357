import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <header className="header123">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <Link to="/" className="logo">
                            <img src={require("../assets/images/logo-as12.png")} alt="" className="logo-main" />
                            <img src={require("../assets/images/name_logo.png")} alt="" className="mobile-logo-main" />
                        </Link>
                        <button
                            style={{ background: "transparent" }} onClick={() => {
                                document.querySelectorAll('.desk-menu').forEach(element => {
                                    element.addEventListener("click", function () {
                                        // Check if the element exists
                                        const menuLeft = document.querySelector('.menu-left');
                                        if (!menuLeft) {
                                            console.error('Element .menu-left does not exist');
                                        } else {
                                            // Toggle class
                                            menuLeft.classList.toggle('enable');
                                            console.log('Class toggled');
                                        }
                                    });
                                });

                            }} className="desk-menu" >
                            <i className="fa fa-bars" style={{ marginTop: -5 }} />
                        </button>
                    </div>
                    <div className="col-md-7">
                        <div className="menu">
                            <ul>
                                <li>
                                    <Link to={"/"}>Home</Link>
                                </li>
                                <li>
                                    <Link to={"/portfolio"}>Portfolio</Link>
                                </li>
                                <li>
                                    <Link to={"/packages"}>Packages</Link>
                                </li>
                                <li>
                                    <Link to={"/testimonial"}>
                                        Testimonial
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/about-us"}>About</Link>
                                </li>
                                <li>
                                    <Link to={"/contact-us"}>Contact</Link>
                                </li>
                                <li>
                                    <Link to={"/policy"}>Policy</Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <button data-src="#popupformWebsite"
                            data-fancybox="" className='px-4 py-0 rounded  orderBtn' style={{ background: "transparent", border: "1px solid #fff", color: "#fff", fontWeight: "600", cursor: "pointer" }}>Order Now</button>

                    </div>
                </div>
            </div>
            <div className="menu-left">
                <div className="menu-lover" />
                <div className="menu-linn">
                    <button onClick={() => {
                        // Select the first element with the class 'menu-left'
                        const element = document.querySelector('.menu-left');

                        // Check if the element exists and remove the class 'enable'
                        if (element) {
                            element.classList.remove('enable');
                        }

                    }} className="menu-lclose" style={{ fontSize: 40, background: "#fff" }}>

                        <i className="fa fa-times" />
                    </button>
                    <div className="main-menu">
                        <ul>
                            <li>
                                <Link onClick={() => {
                                    // Select the first element with the class 'menu-left'
                                    const element = document.querySelector('.menu-left');

                                    // Check if the element exists and remove the class 'enable'
                                    if (element) {
                                        element.classList.remove('enable');
                                    }

                                }} to={"/"}>Home</Link>
                            </li>

                            <li>
                                <Link onClick={() => {
                                    // Select the first element with the class 'menu-left'
                                    const element = document.querySelector('.menu-left');

                                    // Check if the element exists and remove the class 'enable'
                                    if (element) {
                                        element.classList.remove('enable');
                                    }

                                }} to={"/portfolio"}>Portfolio</Link>
                            </li>
                            <li>
                                <Link onClick={() => {
                                    // Select the first element with the class 'menu-left'
                                    const element = document.querySelector('.menu-left');

                                    // Check if the element exists and remove the class 'enable'
                                    if (element) {
                                        element.classList.remove('enable');
                                    }

                                }} to={"/packages"}>Packages</Link>
                            </li>
                            <li>
                                <Link onClick={() => {
                                    // Select the first element with the class 'menu-left'
                                    const element = document.querySelector('.menu-left');

                                    // Check if the element exists and remove the class 'enable'
                                    if (element) {
                                        element.classList.remove('enable');
                                    }

                                }} to={"/testimonial"}>Testimonial</Link>
                            </li>
                            <li>
                                <Link onClick={() => {
                                    // Select the first element with the class 'menu-left'
                                    const element = document.querySelector('.menu-left');

                                    // Check if the element exists and remove the class 'enable'
                                    if (element) {
                                        element.classList.remove('enable');
                                    }

                                }} to={"/about-us"}>About</Link>
                            </li>
                            <li>
                                <Link onClick={() => {
                                    // Select the first element with the class 'menu-left'
                                    const element = document.querySelector('.menu-left');

                                    // Check if the element exists and remove the class 'enable'
                                    if (element) {
                                        element.classList.remove('enable');
                                    }

                                }} to={"/contact-us"}>Contact</Link>
                            </li>
                            <li>
                                <Link onClick={() => {
                                    // Select the first element with the class 'menu-left'
                                    const element = document.querySelector('.menu-left');

                                    // Check if the element exists and remove the class 'enable'
                                    if (element) {
                                        element.classList.remove('enable');
                                    }

                                }} to={"/policy"}>Policy</Link>
                            </li>
                            <li>   <button data-src="#popupformWebsite"
                                data-fancybox="" className='px-4 py-0 rounded ' style={{ background: "transparent", border: "1px solid #111", color: "#111", fontWeight: "600", cursor: "pointer" }}>Order Now</button>
                            </li>
                        </ul>
                    </div>
                    {/* <div className='col-md-2'>
                     
                    </div> */}
                </div>
            </div>
        </header>

    )
}

export default Header