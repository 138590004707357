import { createContext, useContext, useEffect, useState } from "react";
import { BaseAxios } from "../api/BaseApi";


const MainContext = createContext();
const MainContextProvider = ({ children }) => {
    const [allHomePortfolio, setHomePortFolio] = useState(null);
    const [allPortfolio, setPortFolio] = useState(null);
    const [allTestimonial, setAllTestimonial] = useState(null);



    useEffect(() => {
        getAllHomePortfolio();
        getAllPortfolio();
        getAllTestimonial();
    }, []);


    const getAllHomePortfolio = async (categoryId = '1') => {
        try {

            const response = await BaseAxios.get(`/portfolio/${categoryId}`);
            setHomePortFolio(response.data?.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getAllPortfolio = async (categoryId = '1') => {
        try {

            const response = await BaseAxios.get(`/portfolio/all/${categoryId}`);
            setPortFolio(response.data);
        } catch (error) {
            console.log(error);
        }
    }




    const getAllTestimonial = async () => {
        try {

            const response = await BaseAxios.get(`/testimonial`);
            // console.log(response.data);
            setAllTestimonial(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    return <MainContext.Provider
        value={{
            setHomePortFolio,
            allHomePortfolio,
            getAllHomePortfolio,
            allPortfolio,
            setPortFolio,
            getAllPortfolio,
            allTestimonial
        }}
    >
        {children}
    </MainContext.Provider>
}

const useMainContext = () => useContext(MainContext);

export { MainContextProvider, useMainContext }