import React from 'react'

const Cat1 = () => {
    return (
        <section className="our_process process4" 
        style={{background:"#fff",color:"#111"}}
        >
            <div className="container">
                <div className="row">
                    <div className="col-md-12 content-header">
                        <h2 className="latest-head">
                            {" "}
                            Craft a Compelling Brand Identity
                            <br /> That Resonates With Your Audience!
                        </h2>
                    </div>
                    <div className="col-md-4 lat-item orange"   >
                        <div className="lat-item-inn">
                            <div className="lat-item-head">
                                <img src={require("../assets/images/strategy.gif")} className="img-fluide" />
                                <h3 className="lat-name">
                                    <span>Strategy</span>
                                </h3>
                                <p className="lat-txt">
                                    Following thorough market research and analysis, we diligently
                                    select the optimal path to assist you in reaching your objectives.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 lat-item blue">
                        <div className="lat-item-inn">
                            <div className="lat-item-head">
                                <img src={require("../assets/images/design.gif")} className="img-fluide" />
                                <br />
                                <h3 className="lat-name mt-4">
                                    <span>Design</span>
                                </h3>
                                <p className="lat-txt">
                                    We provide our clients with innovative design solutions tailored
                                    to their needs, industry trends, and our expertise.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 lat-item grey">
                        <div className="lat-item-inn">
                            <div className="lat-item-head">
                                <img src={require("../assets/images/result.gif")} className="img-fluide" />
                                <br />
                                <h3 className="lat-name mt-4">
                                    <span>Results</span>
                                </h3>
                                <p className="lat-txt">
                                    As a results-driven company, we pride ourselves on our track
                                    record of facilitating some of the world's most successful and
                                    functional products!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Cat1