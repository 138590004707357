import React from 'react'



const data = {
    "section": {
        "title": "Pricing Plan That Works for Everyone!",
        "description": "We, at Mad Alpha Designers, excel in providing impeccable services at very competitive rates! Align your needs and jump right in to have an experience of a lifetime!",
        "plans": [
            {
                "name": "Solid",
                "description": "Streaming Pack",
                "features": [
                    "1x Logo",
                    "3x Screens",
                    "3x Alerts",
                    "4x Panels",
                    "1x Header",
                    "1x Camscreen",
                    // "1x banner",
                    "1x Chatbox"
                ],
                "actions": {
                    "order_now": "javascript:void(0)",
                    "live_chat": "javascript:$zopim.livechat.window.toggle();",
                    "speak_with_us": {
                        "text": "Speak with us",
                        "phone": "(213) 672-6844"
                    },
                    "discuss": {
                        "text": "Want to discuss?",
                        "live_chat": "javascript:$zopim.livechat.window.toggle();"
                    }
                }
            },
            {
                "name": "Basic",
                "description": "Streaming Pack",
                "features": [
                    "1x Logo",
                    "1x Banner",
                    "3x Screens",
                    "3x Alerts",
                    "4x Panels",
                    // "3x emotes",
                    // "3x sub badges",
                    "1x Header",
                    "1x Camscreen",
                    "1x Chatbox"

                ],
                "actions": {
                    "order_now": "javascript:void(0)",
                    "live_chat": "javascript:$zopim.livechat.window.toggle();",
                    "speak_with_us": {
                        "text": "Speak with us",
                        "phone": "(213) 672-6844"
                    },
                    "discuss": {
                        "text": "Want to discuss?",
                        "live_chat": "javascript:$zopim.livechat.window.toggle();"
                    }
                }
            },
            {
                "name": "Standard",
                "description": "Streaming Pack",
                "features": [
                    "1x Logo",
                    "1x Banner",
                    "3x Screens",
                    "3x Alerts",
                    "4x Panels",
                    "1x Header",
                    "1x Camscreen",
                    "1x Chatbox",
                    "3x Emotes",
                    "3x Sub badges",
                    // "1x animation on logo",
                    // "1x animated overlay",
                ],
                "actions": {
                    "order_now": "javascript:void(0)",
                    "live_chat": "javascript:$zopim.livechat.window.toggle();",
                    "speak_with_us": {
                        "text": "Speak with us",
                        "phone": "(213) 672-6844"
                    },
                    "discuss": {
                        "text": "Want to discuss?",
                        "live_chat": "javascript:$zopim.livechat.window.toggle();"
                    }
                }
            },
            {
                "name": "Professional",
                "description": "Streaming Pack",
                "features": [
                    "1x Logo",
                    "2x Banner",
                    "3x Screens",
                    "3x Alerts",
                    "4x Panels",
                    "1x Header",
                    "1x Camscreen",
                    "1x Chatbox",
                    "3x Emotes",
                    "3x Sub Badges",
                    "1x Intro",
                    "1x Animated Overlay"
                    // "3x screens",
                    // "3x alerts",
                    // "4x panels",
                    // "1x header",
                    // "1x camscreen",

                ],
                "actions": {
                    "order_now": "javascript:void(0)",
                    "live_chat": "javascript:$zopim.livechat.window.toggle();",
                    "speak_with_us": {
                        "text": "Speak with us",
                        "phone": "(213) 672-6844"
                    },
                    "discuss": {
                        "text": "Want to discuss?",
                        "live_chat": "javascript:$zopim.livechat.window.toggle();"
                    }
                }
            },
            {
                "name": "Premium",
                "description": "Streaming Pack",
                "features": [
                    "1x Logo",
                    "3x Banners",
                    "3x Screens",
                    "3x Alerts",
                    "4x Panels",
                    "1x Header",
                    "1x Camscreen",
                    "1x Chatbox",
                    "1x Intro",
                    "1x Outro",
                    "3x Emotes",
                    "3x Sub Badges",
                    "1x Animated Logo",
                    "1x Animated Banner",
                    "3x Animated Emotes",
                    "1x Animated Overlay",
                ],
                "actions": {
                    "order_now": "javascript:void(0)",
                    "live_chat": "javascript:$zopim.livechat.window.toggle();",
                    "speak_with_us": {
                        "text": "Speak with us",
                        "phone": "(213) 672-6844"
                    },
                    "discuss": {
                        "text": "Want to discuss?",
                        "live_chat": "javascript:$zopim.livechat.window.toggle();"
                    }
                }
            }
        ]
    }
}

const PackageComponent = () => {
    return (
        <section style={{
            backgroundImage: 'url("https://d34jlxpwrja7q9.cloudfront.net/wp-content/uploads/2023/02/momoisland-bg.jpg")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%"
            // background: 'linear-gradient(45deg, #25328ef2, transparent)'
        }}>
            <div className="glass-box">
                <div className="container" style={{ padding: 0 }}>
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <div className="col-md-12 content-header">
                            <h2>{data.section.title}</h2>
                            <p>{data.section.description}</p>
                        </div>


                        <div className='col-md-12'>
                            <div className='row' style={{ alignItems: "center" }}>
                                {
                                    data.section.plans.map((v, i) => {
                                        return (
                                            <div class="grid-1-5" key={i}>
                                                {/* <div class="auto-box"> */}
                                                    <div className='topBox'>

                                                        <h2>{v.name}</h2>
                                                        <h3><span class="uppercase">Streaming Pack</span></h3>
                                                    </div>
                                                    {/* <p>1M+ monthly visits</p> */}
                                                    <ul className='list' style={{ marginBottom: '1.6rem' }}>
                                                        {
                                                            v.features.map((f, k) => {
                                                                return <li key={k}>{f}</li>
                                                            })
                                                        }
                                                    </ul>
                                                    <button
                                                        data-src="#popupformWebsite"
                                                        data-fancybox=""
                                                        class="button">Get Quote</button>
                                                {/* </div>/ */}
                                            </div>
                                        );
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PackageComponent