import React from 'react'

const Cat = () => {
  return (
    <section className="our_industry"
    style={{
      background: 'linear-gradient(45deg, #25328ef2, transparent)'
  }}
    >
    <div className="container">
      <div className="row">
        <div className="oi_left col-md-6">
          <h2>We Excel At What We Do &amp; Take Pride in How We Do It!</h2>
          {/*<h5>We stay on top of our industry by being experts in yours!</h5>*/}
          <p>
            We are one of US’s technology driven company and do our best to enable
            quality solutions. Staying ahead of the competition is no easy feat.
            and we have invested years in acquiring the knowledge and expertise
            that have enabled us to become one of a market kinds.
          </p>
          <a
            href="javascript:$zopim.livechat.window.toggle();"

            style={{ background: 'linear-gradient(197deg, #312969, #0000008a)' }}
            className="custBtn chat"
          >
            <span>Talk To Our Expert</span>
          </a>
        </div>
        <div className="oi_right col-md-6">
          <img
            className="lazy"
            src={require("../assets/images/collage.png")}
            data-src={require("../assets/images/collage.png")}
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
  
  )
}

export default Cat