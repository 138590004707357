import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import YouTube from 'react-youtube';

const HomeSlider = () => {
  const whatDevice2 = /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    const iframeYT = document.querySelector("iframe");
    const style = document.createElement("style");
    style.textContent = `
      .ytp-chrome-top.ytp-show-cards-title,
        .ytp-gradient-top,
        .ytp-gradient-bottom {
          display: none;
        }
        a.ytp-watermark.yt-uix-sessionlink.ytp-watermark-small {
          display: none;
        }
    `;
    console.log(iframeYT);
    // iframeYT?.contentDocument.head.appendChild(style);
    // console.dir(iframeYT.contentDocument);
  }, [])

  return (
    <section className="main-banner-st pb-5">
      <div className="container">
        <div className="row">
          {/* <ReactPlayer
            className='react-player'
            url='https://drive.google.com/file/d/1zX4PIJvco4uVmZRExcZT1nvd0FoSNpFH/view'
            width='100%'
            height='100%'
          /> */}
          {/* <video
            style={{
              width: "100%",
              height: "106vh",
              position: "absolute",
              top: "56px",
              left: 0,
              objectFit: "fill",
            }}
            className='SliderVideo' muted autoPlay loop controls={false}

            src={whatDevice2 ? 'video/iphoneVideo.webm' : "https://madalphadesigners.com/admin/public/storage/images/0012%20(2).mp4"} type={!whatDevice2 ? "video/mp4" : "video/webm"}
          >
          </video> */}


          {/* <YouTube
            videoId='7lewWOfzDLs'
            id='homeSlider'
            opts={{
              playerVars: {
                autoplay: 1,
                controls: 0,
                rel: 0,
                showinfo: 0,
                mute: 1,
                loop: 1,
                disablekb:0,


              }
            }}
            onReady={async (d) => {
              // await d.target
              console.log(d);
            }}


          /> */}


          <ReactPlayer url='https://www.youtube.com/watch?v=7lewWOfzDLs'
            controls={false}
            loop={true}
            muted={true}
            onReady={(d) => {
              console.log(d);
              const iframeYT = document.querySelector("iframe");
              const style = document.createElement("style");
              style.textContent = `
                .ytp-chrome-top.ytp-show-cards-title,
                  .ytp-gradient-top,
                  .ytp-gradient-bottom {
                    display: none;
                  }
                  a.ytp-watermark.yt-uix-sessionlink.ytp-watermark-small {
                    display: none;
                  }
              `;
              if (iframeYT.contentDocument) {
                iframeYT.contentDocument.head.appendChild(style);
              } else {
                if (iframeYT.contentWindow.document) {
                  iframeYT?.contentWindow?.document?.head?.appendChild(style);
                }
              }
            }}
            width={'100%'}
            height={'109vh'}
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen
            playing={true}
            style={{
              width: '100%',
              position: 'absolute',
              height: '109vh',
              top: 0,
              left: 0
            }}

          />



        </div>
      </div>
    </section>
  )
}

export default HomeSlider