import React from 'react'
import Typed from 'typed.js';

const PortfolioSlider = () => {

    const el = React.useRef(null);

    React.useEffect(() => {
        const typed = new Typed(el.current, {
            strings: ['Our Portfolio Design at its Best'],
            typeSpeed: 50,
            loop: true,
            startDelay: 500
        });

        return () => {
            // Destroy Typed instance during cleanup to stop animation
            typed.destroy();
        };
    }, []);
    return (
        <>
            <section
                className="inner-banner"
                style={{
                    backgroundColor: "#436d3d",
                    backgroundImage: "url(images/portfoliobg.png)",
                    height:"100vh"
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 banner-left banner-left123">
                            <h1>
                                
                                {/* <span /> */}
                                <span ref={el} />

                            </h1>
                            <p>
                                Looking for design inspiration? Browse our portfolio to see our
                                creative grind.
                            </p>
                            <a
                                data-fancybox=""
                                href="javascript:void(0)"
                                data-src="#popupformWebsite"
                                title="Lets Get Started"
                                className="banner-btn yb-btn various"
                                style={{ background: 'linear-gradient(197deg, #312969, #0000008a)' }}

                            >
                                 Get Touch In
                            </a>
                        </div>
                        <div className='col-md-4 image12'
                            style={{
                                position: 'absolute',
                                right: '10px',
                                zIndex: -1
                            }}
                        >
                            <img
                                className='zoom-in-out-box'
                                src='images/portfolio.png' style={{ height: "46vh",position:'absolute',top:'-20px',left:"5rem" }} />
                        </div>
                        <div className="col-md-5 banner-right"></div>
                    </div>
                </div>
            </section>
            <section className="cp-logo">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <img src={require("../assets/images/cplogo.png")} alt="" /> */}
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default PortfolioSlider