import React from 'react'
import HomeForm from '../Components/HomeForm'
import TestimonialSlider from '../Components/TestimonialSlider'

const Contact = () => {
  return (
    <>
      <section
        className="inner-banner"
        style={{
          backgroundColor: "#17335b",
          backgroundImage: "url(images/contactusBG.png)",
          height: "100vh",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 ">
              <h1>
                Get In Touch <br />
                With Us <span>24/7! </span>
              </h1>
              <p className="banner_shoulder">
                We love taking on exciting new projects
                <br /> for aspiring businesses and growth-oriented clients.
                <br /> Get in touch with us today for a free strategic consultation!
              </p>
            </div>
            <div className="col-md-4 col-12">
              <img src="images/contact-us.png"
                style={{
                  height: "313px",
                  width: "333px",
                  position: 'absolute',
                  top: '-34px ',
                }}
                className='zoom-in-out-box image13'
                alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="cp-logo">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <img src="assets/images/cplogo.png" alt="" /> */}
            </div>
          </div>
        </div>
      </section>




      <section className="contact-form" style={{ background: "#fff", color: "#111", marginTop: 0 }}>
        <div className="container">
          <div className="row">
            <div className="col-md-5 contact-right">
              <h2>
                Fill out this form to schedule <br /> <span style={{ color: 'rgba(135, 162, 235, 0.71)' }} className='freeText'>Free</span>{" "}
                Consultation.
              </h2>
              <p>
                Tell us your business goals and we’ll see how we can create
                something amazing together. Get in touch with us today to get
                started on your project!
              </p>
              <div className="contact-form">
                <form
                  action="/webpages/bannerFormController.php"
                  method="POST"
                  className="banner-form-val"
                >
                  <div className="cf-fieds">
                    <ul>
                      <li>
                        <i className="fa fa-user" />
                        <input
                          type="text"
                          className="required placeholder2"
                          required=""
                          placeholder="Full Name *"
                          name="Name"
                          aria-required="true"
                        />
                        <input
                          type="hidden"
                          name="form-name"
                          defaultValue="Contact Us Form Query"
                        />
                      </li>
                      <li>
                        <i className="fa fa-envelope" />
                        <input
                          type="email"
                          className="required email placeholder2"
                          required=""
                          placeholder="Email Address *"
                          name="Email"
                          aria-required="true"
                        />
                      </li>
                      <li>
                        <i className="fa fa-phone" />
                        <input
                          type="text"
                          className="required number placeholder2"
                          minLength={10}
                          required=""
                          placeholder="Phone No. *"
                          name="Number"
                          aria-required="true"
                        />
                      </li>
                      <li>
                        <i className="fa fa-comment" />
                        <textarea
                          name="Message"
                          className="required placeholder2"
                          required=""
                          placeholder="To help us understand better, enter a brief description about your project."
                          defaultValue={""}
                        />
                      </li>
                      <li className="sbmit-btn">
                        <input type="submit"
                          style={{ background: 'linear-gradient(197deg, #312969, #0000008a)' }}

                          defaultValue="Submit" />
                        <input
                          className=""
                          type="hidden"
                          name="ctry"
                          defaultValue=""
                        />
                        <input type="hidden" name="pc" defaultValue="" />
                        <input type="hidden" name="cip" defaultValue="" />
                        <input type="hidden" name="hiddencapcha" defaultValue="" />
                        <input
                          type="hidden"
                          id="location"
                          name="locationURL"
                          defaultValue="https://madalphadesigners.com/contact-us"
                        />
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-7 contact-left">
              <div className="map">
                <img src={require("../assets/images/ontact-element.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cont-addres">
        <div className="container">
          <div className="contact-line col-md-12">
            <div className="contact-hd">
              <h3>Additional Inquiries.</h3>
              <p>For immediate support, feel free to call us on</p>
            </div>
            <ul>
              <li className="cgreen">
                <a href="mailto:connect@madalphadesigners.com">
                  <span className="clabel">Email :</span>{" "}
                  connect@madalphadesigners.com
                </a>
              </li>
              {/* <li className="cloc"><a href="javascript:void(0)"><span className="clabel">Mailing Address :</span> 1 Mann Island, Liverpool, <br>Merseyside L3 1BP</a></li> */}
              <li className="coragne">
                <a href="tel:+12136726844">
                  <span className="clabel">Phone:</span>+1 (409) 419-3427
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <HomeForm />
    </>

  )
}

export default Contact