import React, { useState } from 'react';
import Masonry from "react-responsive-masonry";
import { BASEIMAGEURL } from '../api/BaseApi';
import { Link } from 'react-router-dom';

const DataTabs = [
    {
        id: 1,
        title: "Logo Design",
        isActive: true
    },
    {
        id: 2,
        title: "Animations",
        isActive: false
    },
    {
        id: 3,
        title: "2D Model",
        isActive: false
    },
    {
        id: 4,
        title: "3D Model",
        isActive: false
    },
    {
        id: 5,
        title: "Banner",
        isActive: false
    },
    {
        id: 6,
        title: "Overlays",
        isActive: false
    },
    // {
    //     id: 7,
    //     title: "PFP",
    //     isActive: false
    // },
    {
        id: 8,
        title: "Emotes/Badges",
        isActive: false
    },
    {
        id: 9,
        title: "Custom Gaming Room",
        isActive: false
    },
    {
        id: 10,
        title: "AutoCAD",
        isActive: false
    },
    {
        id: 11,
        title: "Website UI/UX",
        isActive: false
    },



];

const Portfolio = ({ bg = "#fff", data, onClickMenu, isHome = false }) => {
    // const {  } = useMainContext();
    const [linkList, setLinksList] = useState(DataTabs);
    const toggleActive = (id) => {
        setLinksList(
            linkList.map((item) =>
                // item.id === id ? { ...item, isActive: !item.isActive } : item
                item.id === id ? { ...item, isActive: true } : { ...item, isActive: false }

            )
        );
    };

    return (

        <section className="portfolio2"
            style={{

                // backgroundImage:'url("https://www.nftculture.com/wp-content/uploads/2023/02/momoisland-bg.jpg")',
                background: bg,
                color: bg != "#fff" ? "#fff" : "#111",
                // padding: 0
            }}
        >
            <div className="glass-box1">


                <div className="container">
                    <div className="row">
                        <div className="col-md-12 content-header">
                            <h2>Our Recent Projects</h2>
                            <p>Seeking design inspiration? Explore our portfolio to delve into our creative journey. </p>
                        </div>
                    </div>

                    <ul className="tabs2">
                        {
                            linkList.map((v, i) => {
                                return (
                                    <li
                                        onClick={(e) => {
                                            onClickMenu(v.id.toString());
                                            toggleActive(v.id);
                                        }}
                                        key={i} className={`tab-link ${v.isActive && (bg != '#fff' ? 'current2' : 'current1')} ${bg != '#fff' && 'tabLink'}`} >{v.title}</li>
                                )
                            })
                        }
                    </ul>

                    {/* <ResponsiveMasonry
                        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                    > */}
                    <Masonry columnsCount={data == null || data?.length <= 0 ? 1 : 4} gutter={20}>
                        {data == null ?
                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                <img src={'images/loader.svg'} width={70} height={70} />
                            </div>
                            : data?.length <= 0 ?
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", paddingBlock: 100 }}>
                                    <p style={{ color: "rgb(119 119 119 / 44%)", fontSize: 30, fontWeight: 600 }}>Not Founded</p>
                                </div>
                                :
                                data?.map((v, i) => {
                                    return (
                                        // <div className="box">
                                        <a className='p_image' data-fancybox=""
                                            key={i}

                                            tabIndex="0" href={`${BASEIMAGEURL}/${v?.image}`}>
                                            <img style={{ borderRadius: 10 }} className="lazyload rounded" src={`${BASEIMAGEURL}/${v?.image}`} alt="" />
                                        </a>

                                        // </div>
                                    )
                                })
                        }
                    </Masonry>
                    {/* </ResponsiveMasonry> */}


                    {isHome && <div className='row' style={{ paddingBlock: 40 }}>
                        <div className='col-md-4'></div>
                        <div className='col-md-4 col-12' style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                            <Link to={'/portfolio'} className='px-4 py-2 rounded ' style={{ background: "transparent", border: "1px solid #fff", color: "#fff", fontWeight: "600", cursor: "pointer" }}>See More</Link>
                        </div>
                        <div className='col-md-4'></div>
                    </div>}

                </div>
            </div>
        </section>
    )
}

export default Portfolio