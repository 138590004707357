import React from 'react'
import Cat1 from '../Components/Cat1'
import Cat from '../Components/Cat'
import HomeTestimonial from '../Components/HomeTestimonial'
import HomeForm from '../Components/HomeForm'
import PackageComponent from '../Components/PackageComponent'
import TestimonialSlider from '../Components/TestimonialSlider'
import Typed from 'typed.js'


const data = {
  "section": {
    "title": "Pricing Plan That Works for Everyone!",
    "description": "We, at Mad Alpha Designers, excel in providing impeccable services at very competitive rates! Align your needs and jump right in to have an experience of a lifetime!",
    "plans": [
      {
        "name": "Solid",
        "description": "Streaming Pack",
        "features": [
          "1x Logo",
          "3x Screens",
          "3x Alerts",
          "4x Panels",
          "1x Header",
          "1x Camscreen",
          "1x Chatbox",
          "100% Satisfaction Guarantee",
          "100% Money Back Guarantee *",
          "100% Ownership Rights"
        ],
        "actions": {
          "order_now": "javascript:void(0)",
          "live_chat": "javascript:$zopim.livechat.window.toggle();",
          "speak_with_us": {
            "text": "Speak with us",
            "phone": "(213) 672-6844"
          },
          "discuss": {
            "text": "Want to discuss?",
            "live_chat": "javascript:$zopim.livechat.window.toggle();"
          }
        }
      },
      {
        "name": "Basic",
        "description": "Streaming Pack",
        "features": [
          "1x Logo",
          "1x Banner",
          "3x Screens",
          "3x Alerts",
          "4x Panels",
          "1x Header",
          "1x Camscreen",
          "1x Chatbox",
          "100% Satisfaction Guarantee",
          "100% Money Back Guarantee *",
          "100% Ownership Rights"
        ],
        "actions": {
          "order_now": "javascript:void(0)",
          "live_chat": "javascript:$zopim.livechat.window.toggle();",
          "speak_with_us": {
            "text": "Speak with us",
            "phone": "(213) 672-6844"
          },
          "discuss": {
            "text": "Want to discuss?",
            "live_chat": "javascript:$zopim.livechat.window.toggle();"
          }
        }
      },
      {
        "name": "Professional",
        "description": "Streaming Pack",
        "features": [
          "1x Logo",
          "1x Banner",
          "3x Screens",
          "3x Alerts",
          "4x Panels",
          "1x Header",
          "1x Camscreen",
          "1x Chatbox",
          "3x Emotes",
          "3x Sub Badges",
          "100% Satisfaction Guarantee",
          "100% Money Back Guarantee *",
          "100% Ownership Rights"
        ],
        "actions": {
          "order_now": "javascript:void(0)",
          "live_chat": "javascript:$zopim.livechat.window.toggle();",
          "speak_with_us": {
            "text": "Speak with us",
            "phone": "(213) 672-6844"
          },
          "discuss": {
            "text": "Want to discuss?",
            "live_chat": "javascript:$zopim.livechat.window.toggle();"
          }
        }
      },
      {
        "name": "Premium",
        "description": "Streaming Pack",
        "features": [
          "1x Logo",
          "3x Banner",
          "3x Screens",
          "3x Alerts",
          "4x Panels",
          "1x Header",
          "1x Camscreen",
          "1x Chatbox",
          "3x Emotes",
          "3x Sub Badges",
          "1x Intro",
          "1x Outro",
          "3x Animated Emotes",
          "1x Animated Banner",
          "1x Animated Logo",
          "1x Animated Overlay",
          "100% Satisfaction Guarantee",
          "100% Money Back Guarantee *",
          "100% Ownership Rights"
        ],
        "actions": {
          "order_now": "javascript:void(0)",
          "live_chat": "javascript:$zopim.livechat.window.toggle();",
          "speak_with_us": {
            "text": "Speak with us",
            "phone": "(213) 672-6844"
          },
          "discuss": {
            "text": "Want to discuss?",
            "live_chat": "javascript:$zopim.livechat.window.toggle();"
          }
        }
      }
    ]
  }
}
const Packages = () => {
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['Competitive Pricing Plans '],
      typeSpeed: 50,
      loop: true,
      startDelay: 500
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);
  return (
    <>
      <section
        className="inner-banner packageSliderImage"
        style={{
          backgroundColor: "#436d3d",
          backgroundImage: "url(images/packages-Banner.png)",
           height:"100vh"
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 banner-left banner-left123">
              <h1>

                <span ref={el} />
                {/* <span /> */}
              </h1>
              <p>
                Assisting you with complete brand development services for seed
                sized and monster sized organizations.
              </p>
              <a
                data-fancybox=""
                href="javascript:void(0)"
                data-src="#popupformWebsite"
                title="Lets Get Started"
                className="banner-btn yb-btn various"
                style={{ background: 'linear-gradient(197deg, #312969, #0000008a)' }}
              >
                Get Touch In
              </a>
            </div>
            <div className="col-md-5 banner-right"></div>
          </div>
        </div>
      </section>
      <section className="cp-logo">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <img src="assets/images/cplogo.png" alt="" /> */}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="py-5">
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-md-12 content-header">
              <h2>{data.section.title}</h2>
              <p>{data.section.description}</p>
            </div>

            {
              data.section.plans.map((v, i) => {
                return (<div
                  key={i}
                  className="col-md-4 pack-item">
                  <div className="pack-inn">
                    <div className="pack-header">
                      <h3 className="pack-name">{v.name}</h3>
                      <p>{v.description}</p>
                    </div>

                    <div className="pack-list pack-ul pt-5">
                      <ul>
                        {
                          v.features.map((f, k) => {
                            return <li key={k}>{f}</li>
                          })
                        }

                      </ul>
                    </div>
                    <div className="pack-btn">
                      <ul>
                        <li><a data-fancybox="" title="Order Now" data-src="#popupformWebsite" className="norder_now various" href="javascript:void(0)">start project</a></li>
                        <li><a className="live_chat chat" href="javascript:$zopim.livechat.window.toggle();">Live Chat</a></li>
                      </ul>
                    </div>
                    <div className="pack-lbtn">
                      <a href="tel:+12136726844" className="live_call"><span>Speak with us</span> (213) 672-6844 </a>
                      <a href="javascript:$zopim.livechat.window.toggle();" className="chat"><span>Want to discuss?</span> Live Chat Now </a>
                    </div>
                  </div>
                </div>)
              })
            }
          </div>
        </div>
      </section> */}
      <PackageComponent />
      {/* <Cat1 /> */}
      <Cat />
      {/* <TestimonialSlider /> */}
      {/* <HomeTestimonial /> */}
      <HomeForm />
    </>

  )
}

export default Packages