import React from 'react'
import HomeForm from '../Components/HomeForm'
import HomeSlider from '../Components/HomeSlider'
import PackageComponent from '../Components/PackageComponent'
import Portfolio from '../Components/Portfolio'
import { useMainContext } from '../Context/MainContext'
// import ParticleBackground from '../Components/ParticleBackground'


const Home = () => {
    const { allHomePortfolio, getAllHomePortfolio, setHomePortFolio } = useMainContext();
    return (
        <>
            <HomeSlider />
            <Portfolio data={allHomePortfolio}
                onClickMenu={(d) => {
                    setHomePortFolio(null)
                    getAllHomePortfolio(d);
                }}
                isHome={true}
                bg={'linear-gradient(45deg, #25328ef2, transparent)'}
            />
            <PackageComponent />
            <div id="testimonialCarousel" className="carousel" style={{ paddingInline: 40 }}></div>
            {/* <section
                style={{
                    background: 'rgb(248,249,250)',
                    paddingBlock: 80
                }}
            >
                <div className="container-fluid bg-body-tertiary py-3">
                    <div className="col-md-12 content-header">
                        <h2 style={{ color: "#111" }}>{"Testimonial"}</h2>
                        <p style={{ color: "#111" }}>{"we hold the conviction that hidden within each impossibility is a possibility eager to emerge."}</p>
                    </div>
                    <div id="testimonialCarousel" className="carousel" style={{ paddingInline: 40 }}>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="card shadow-sm rounded-3">
                                    <div className="quotes display-2 text-body-tertiary">
                                        <i className="bi bi-quote" />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            "Some quick example text to build on the card title and make up
                                            the bulk of the card's content."
                                        </p>
                                        <div className="d-flex align-items-center pt-2">
                                            <img
                                                src="https://codingyaar.com/wp-content/uploads/square-headshot-1.png"
                                                alt="bootstrap testimonial carousel slider 2"
                                            />
                                            <div>
                                                <h5 className="card-title fw-bold">Jane Doe</h5>
                                                <span className="text-secondary">CEO, Example Company</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="card shadow-sm rounded-3">
                                    <div className="quotes display-2 text-body-tertiary">
                                        <i className="bi bi-quote" />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            "Some quick example text to build on the card title and make up
                                            the bulk of the card's content."
                                        </p>
                                        <div className="d-flex align-items-center pt-2">
                                            <img
                                                src="https://codingyaar.com/wp-content/uploads/square-headshot-2.png"
                                                alt="bootstrap testimonial carousel slider 2"
                                            />
                                            <div>
                                                <h5 className="card-title fw-bold">June Doe</h5>
                                                <span className="text-secondary">CEO, Example Company</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="card shadow-sm rounded-3">
                                    <div className="quotes display-2 text-body-tertiary">
                                        <i className="bi bi-quote" />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            "Some quick example text to build on the card title and make up
                                            the bulk of the card's content."
                                        </p>
                                        <div className="d-flex align-items-center pt-2">
                                            <img
                                                src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                                                alt="bootstrap testimonial carousel slider 2"
                                            />
                                            <div>
                                                <h5 className="card-title fw-bold">John Doe</h5>
                                                <span className="text-secondary">CEO, Example Company</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="card shadow-sm rounded-3">
                                    <div className="quotes display-2 text-body-tertiary">
                                        <i className="bi bi-quote" />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            "Some quick example text to build on the card title and make up
                                            the bulk of the card's content."
                                        </p>
                                        <div className="d-flex align-items-center pt-2">
                                            <img
                                                src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                                                alt="bootstrap testimonial carousel slider 2"
                                            />
                                            <div>
                                                <h5 className="card-title fw-bold">John Doe</h5>
                                                <span className="text-secondary">CEO, Example Company</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="card shadow-sm rounded-3">
                                    <div className="quotes display-2 text-body-tertiary">
                                        <i className="bi bi-quote" />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            "Some quick example text to build on the card title and make up
                                            the bulk of the card's content."
                                        </p>
                                        <div className="d-flex align-items-center pt-2">
                                            <img
                                                src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                                                alt="bootstrap testimonial carousel slider 2"
                                            />
                                            <div>
                                                <h5 className="card-title fw-bold">John Doe</h5>
                                                <span className="text-secondary">CEO, Example Company</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="card shadow-sm rounded-3">
                                    <div className="quotes display-2 text-body-tertiary">
                                        <i className="bi bi-quote" />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            "Some quick example text to build on the card title and make up
                                            the bulk of the card's content."
                                        </p>
                                        <div className="d-flex align-items-center pt-2">
                                            <img
                                                src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                                                alt="bootstrap testimonial carousel slider 2"
                                            />
                                            <div>
                                                <h5 className="card-title fw-bold">John Doe</h5>
                                                <span className="text-secondary">CEO, Example Company</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="card shadow-sm rounded-3">
                                    <div className="quotes display-2 text-body-tertiary">
                                        <i className="bi bi-quote" />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            "Some quick example text to build on the card title and make up
                                            the bulk of the card's content."
                                        </p>
                                        <div className="d-flex align-items-center pt-2">
                                            <img
                                                src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                                                alt="bootstrap testimonial carousel slider 2"
                                            />
                                            <div>
                                                <h5 className="card-title fw-bold">John Doe</h5>
                                                <span className="text-secondary">CEO, Example Company</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="card shadow-sm rounded-3">
                                    <div className="quotes display-2 text-body-tertiary">
                                        <i className="bi bi-quote" />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            "Some quick example text to build on the card title and make up
                                            the bulk of the card's content."
                                        </p>
                                        <div className="d-flex align-items-center pt-2">
                                            <img
                                                src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                                                alt="bootstrap testimonial carousel slider 2"
                                            />
                                            <div>
                                                <h5 className="card-title fw-bold">John Doe</h5>
                                                <span className="text-secondary">CEO, Example Company</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="card shadow-sm rounded-3">
                                    <div className="quotes display-2 text-body-tertiary">
                                        <i className="bi bi-quote" />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            "Some quick example text to build on the card title and make up
                                            the bulk of the card's content."
                                        </p>
                                        <div className="d-flex align-items-center pt-2">
                                            <img
                                                src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                                                alt="bootstrap testimonial carousel slider 2"
                                            />
                                            <div>
                                                <h5 className="card-title fw-bold">John Doe</h5>
                                                <span className="text-secondary">CEO, Example Company</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#testimonialCarousel"
                            data-bs-slide="prev"
                        >
                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#testimonialCarousel"
                            data-bs-slide="next"
                        >
                            <span className="carousel-control-next-icon" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </section> */}
            <section className="our_experience"
                style={{
                    background: "#060512",
                    color: "#fff",
                    position: 'relative'
                }}
            >
                {/* <ParticleBackground /> */}
                <div className='bgImage' style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }}>
                    <img src='https://github.blog/wp-content/uploads/2020/12/layers-loop.h264.2020-12-21-11_16_56.gif?resize=640%2C409' />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 content-header">
                            <h2 className="latest-head">
                                {" "}
                                Over 10 Years of Experience in the <br /> Online Design Industry{" "}
                            </h2>
                            <p>
                                The digital services we offer are extensive, yet at every point our
                                work is underpinned by one core aim:
                                <br />
                                to drive relevant consumer traffic to your brand or website.
                            </p>
                        </div>
                        <div className="col-md-3 exp-item">
                            <div className="exp-item-inn">
                                <div className="exp-item-head">
                                    <h3 className="exp-name">
                                        <span className="counter" style={{ color: 'rgba(135, 162, 235, 0.71)' }}>5678</span>
                                    </h3>
                                    <p className="exp-txt">Satisfied Customers</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 exp-item">
                            <div className="exp-item-inn">
                                <div className="exp-item-head">
                                    <h3 className="exp-name">
                                        <span className="counter" style={{ color: 'rgba(135, 162, 235, 0.71)' }}>9892</span>
                                    </h3>
                                    <p className="exp-txt">Projects Completed</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 exp-item">
                            <div className="exp-item-inn">
                                <div className="exp-item-head">
                                    <h3 className="exp-name">
                                        <span className="counter" style={{ color: 'rgba(135, 162, 235, 0.71)' }}>15890</span>
                                    </h3>
                                    <p className="exp-txt">Launched Products</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 exp-item">
                            <div className="exp-item-inn">
                                <div className="exp-item-head">
                                    <h3 className="exp-name">
                                        <span className="counter" style={{ color: 'rgba(135, 162, 235, 0.71)' }}>1015</span>
                                    </h3>
                                    <p className="exp-txt">Daily Visits</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Cat1 />
            <HomeTestimonial /> */}
            <HomeForm
                background={"#060512"}
            />
        </>
    )
}

export default Home