import React from 'react'
import HomeForm from '../Components/HomeForm'

const Policy = () => {
  return (
    <>
      <section
        className="inner-banner"
        style={{
          backgroundColor: "#17335b",
          backgroundImage: "url(images/privacy-PolicyBG.png)",
          height:"100vh",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 ">
              <br />
              <br />
              <br />
              <h1 className='policyHead'> Our Privacy Policy</h1>
              <br />
              <br />
              <br />
            </div>
            <div className="col-md-4 col-12">
              <img src="images/Privacy Policy.png"
                style={{
                  height: "313px",
                  width: "333px",
                  position: 'absolute',
                  top: '-64px',
                }}
                className='zoom-in-out-box image14'
                alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="cp-logo">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <img src="assets/images/cplogo.png" alt="" /> */}
            </div>
          </div>
        </div>
      </section>

      <section className="contact-form" style={{ background: "#fff", color: "#111", marginTop: 0 }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 contact-right">
              <h2>Our Privacy Policy</h2>
              <p>
                Only image references, text references, and design commissions of the to-be-drawn characters will be accepted at this time.
              </p>
              <ul>
                <li style={{ marginBlock: 10 }}>
                  ♔ You as the commissioner are responsible for the quality of your references. please make sure your requests and references are up-to-date, clear, and concise, or you might not receive an accurate depiction of your character.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ If i, the artist, have not started your request you can amend the request by submitting the provided form again with all the required information. you as the commissioner are responsible for providing all of the information to your request in one form.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ Completed commissions are not permitted to be edited or colored by another artist including the commissioner. cropping for profile pictures is allowed as the one exception.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ I, the artist, will not be held accountable to correct or adjust commissions due to unclear or undefined information provided by the commissioner in the order form. i, the artist, will be accountable to correct or adjust commissions where i have failed to follow provided reference material and requests.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ If artwork needs additional edits and the fault lies in the provided reference material by the commissioner edits may be requested without an additional fee.
                </li>
              </ul>
            </div>

            <div className="col-md-12 contact-right mt-4">
              <h2>Disclaimer</h2>
              {/* <p>
                Only image references, text references, and design commissions of the to-be-drawn characters will be accepted at this time.
              </p> */}
              <ul>
                <li style={{ marginBlock: 10 }}>
                  ♔ I reserve the right to refund a commission at any time if i feel i am unable to complete the request.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ I reserve the right to update or clarify any of these conditions at any time or as need be to better protect myself as the artist and you as the commissioner from misunderstanding or wrongful harm.
                </li>

              </ul>
            </div>
            <div className="col-md-12 contact-right mt-4">
              <h2>Payment</h2>
              <p>
                USD Currency.
              </p>
              <ul>
                <li style={{ marginBlock: 10 }}>
                  ♔ I, the artist, require payment of 30% upfront before i start drawing.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ If i, the artist, have not started your request you can amend the request by submitting the provided form again with all the required information. you as the commissioner are responsible for providing all of the information to your request in one form.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ If you make a payment below 30% the prices will increase, if you make more than 50% payment, you will get an additional discount.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ Even though i am a sfw artist, all commissioned work must be ordered by persons who are 18+ and can legally agree to these terms.
                </li>
              </ul>
            </div>

            <div className="col-md-12 contact-right mt-4">
              <h2>Invoice Contract</h2>
              {/* <p>
                USD Currency.
              </p> */}
              <ul>
                <li style={{ marginBlock: 10 }}>
                  ♔ You are agreeing to pay the price for the artist to perform a service to produce your request.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ Once you pay the invoice you agree to all terms and conditions listed here and the work is bound by contract.
                  refunds and time frame.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ Refunds are not offered for contracted work.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ If i, the artist, am unable to complete the request, a refund will be provided.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ The time frame for completion is between 2 to 6 weeks.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ If 8 weeks pass and no work has been completed by the artist this is a breach of contract. the client can ask for a full refund at this time or continue to wait for the art of their choice.
                </li>
              </ul>
            </div>

            <div className="col-md-12 contact-right mt-4">
              <h2>Wips (work in progress screen-caps)</h2>
              {/* <p>
                USD Currency.
              </p> */}
              <ul>
                <li style={{ marginBlock: 10 }}>
                  ♔ You will be provided with a wip preview at every stage of the project.
                </li>
              </ul>
            </div>

            <div className="col-md-12 contact-right mt-4">
              <h2>Art and Artistic rights</h2>
              {/* <p>
                USD Currency.
              </p> */}
              <ul>
                <li style={{ marginBlock: 10 }}>
                  ♔ I, the artist, reserve all artistic rights to commissioned work. i, the artist, may use artwork in online or physical galleries, portfolios, printed material, any form of publication, or distribution with credit to the commissioner for commission credits unless the commission is purchased as a work for hire at the time of the initial order.
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ You, as the commissioner, may use commissioned work for personal display pending artist credits/gallery links are posted with the display back to the artist. (i.e. online icons, online signatures, layouts, wallpapers, social sites, ect)
                </li>
                <li style={{ marginBlock: 10 }}>
                  ♔ I, as the artist, will deliver the final product of the commission to the person who purchased the artwork when it is completed. public posts of the artwork by the artist will credit the commissioner and whom the piece depicts.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="cont-addres">
        <div className="container">
          <div className="contact-line col-md-12">
            <div className="contact-hd">
              <h3>Additional Inquiries.</h3>
              <p>For immediate support, feel free to call us on</p>
            </div>
            <ul>
              <li className="cgreen">
                <a href="mailto:connect@madalphadesigners.com">
                  <span className="clabel">Email :</span> connect@madalphadesigners.com
                </a>
              </li>
              {/* <li className="cloc"><a href="javascript:void(0)"><span className="clabel">Mailing Address :</span> 1 Mann Island, Liverpool, <br>Merseyside L3 1BP</a></li> */}
              {/* <li className="coragne"> */}
                {/* <a href="tel:+12136726844">
                  <span className="clabel">Phone:</span>(213) 672-6844
                </a> */}
              {/* </li> */}
            </ul>
          </div>
        </div>
      </section>

      <HomeForm />
    </>

  )
}

export default Policy