import React from 'react'

const HelpSec = ({bg="linear-gradient(253deg, #181116, #250f23)"}) => {
    return (
        <section className="help-sec"
        style={{
            background:bg,
            color:bg=="#fff"?"#111":"#fff"
        }}
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12">
                        <div className="hd-txt">
                            <h2 className="heading">
                                Empowering Businesses to Excel Through
                                <br /> Tailored Digital Marketing Solutions
                            </h2>
                            <p>
                                At Mad Alpha Designer, we're dedicated to propelling businesses
                                forward through our expertly crafted design solutions. Specializing
                                in the art of visual storytelling, we bring your brand's essence to
                                life with stunning 2D and 3D animations that capture and engage your
                                audience like never before. Our team of creative minds works
                                tirelessly to ensure your message stands out in today's crowded
                                digital landscape, focusing solely on design that speaks volumes.
                                With us, it's not just about creating visuals; it's about crafting
                                stories that resonate and leave a lasting impact.
                            </p>
                        </div>
                        <div className="hepling-icon">
                            <ul>
                                <li>
                                    <div className="h-icon">
                                        <img src={require("../assets/images/help-icon-1.gif")} />
                                        <p>
                                            Constant Client
                                            <br />
                                            Coordination
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="h-icon">
                                        <img src={require("../assets/images/help-icon-2.gif")} />
                                        <p>
                                            Supreme Customer
                                            <br />
                                            Satisfaction
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="h-icon">
                                        <img src={require("../assets/images/help-icon-3.gif")} />
                                        <p>
                                            100% Ownership
                                            <br />
                                            Rights
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="h-icon">
                                        <img src={require("../assets/images/help-icon-4.gif")} />
                                        <p>
                                            Secure Money Back
                                            <br />
                                            Guarantee
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="h-icon">
                                        <img src={require("../assets/images/help-icon-5.gif" )}/>
                                        <p>
                                            Industry Proven
                                            <br />
                                            Professionals
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex">
                        <ul className="nserv-btn">
                            <li>
                                <a
                                    className="norder_now various"
                                    href="javascript:$zopim.livechat.window.toggle()"
                                    style={{ background: 'linear-gradient(197deg, #312969, #0000008a)',marginLeft:"10rem" }}

                                >
                                    Live Chat
                                </a>
                            </li>
                            <li>
                                <a className="nview_price" href="tel:+12136726844">
                                    (213) 672-6844
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default HelpSec