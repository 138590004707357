import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { MainContextProvider } from './Context/MainContext';


const Main = () => {
    return (
        <MainContextProvider>
            <App />
        </MainContextProvider>
    )
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <App />
    // </MainContextProvider>
    <Main />
);


